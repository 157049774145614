import { lightBlueColor } from "@/constants/colors";
import ArrowRightIcon from "@/svg/ArrowRightIcon";
import { css } from "@emotion/react";
import { ReactNode } from "react";
import { Heading } from "../Typography";

interface LinkWithArrowProps {
  children: ReactNode;
}

const LinkWithArrow = ({ children }: LinkWithArrowProps) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        color: ${lightBlueColor};
        transition: color 0.2s ease-in-out;
        &:hover {
          color: #45abe7;
        }
      `}
    >
      <Heading
        css={css`
          font-size: 16px;
          font-weight: 350;
        `}
        level={6}
      >
        {children}
      </Heading>
      <ArrowRightIcon />
    </div>
  );
};

export default LinkWithArrow;
