// import summaryImage from "../../public/images/homepage/features/summary.jpg";
import websiteImage from "../../public/images/homepage/features/website-e.webp";
import registrationImage from "../../public/images/homepage/features/registration.png";
import communicationImage from "../../public/images/homepage/features/communication.jpg";
import schedulingImageMobile from "../../public/images/homepage/features/mobile/scheduling.jpg";
import schedulingImageDesktop from "../../public/images/homepage/features/desktop/scheduling.jpg";
import backgroundChecksImageMobile from "../../public/images/homepage/features/mobile/background-checks.jpg";
import backgroundChecksImageDesktop from "../../public/images/homepage/features/desktop/background-checks.jpg";
import donationsImageMobile from "../../public/images/homepage/features/mobile/donations.jpg";
import donationsImageDesktop from "../../public/images/homepage/features/desktop/donations.jpg";

import {
  backgroundCheckPath,
  communicationPath,
  donationsPath,
  registrationPath,
  schedulingPath,
  // summaryPath,
  websiteBuilderPath,
} from "./paths";

export const homepageFeatures1 = [
  // {
  //   imageSrc: summaryImage,
  //   tag: "Overview",
  //   title: "How does it work?",
  //   description:
  //     "Use our simple website manager that's built for sports volunteers. Set up a website in minutes using our website builder. Create pages, add photos & documents, post your schedules, and more!",
  //   href: summaryPath,
  // },
  {
    imageSrc: websiteImage,
    tag: "Website Builder",
    title: "Build a website in minutes",
    description:
      "Our lightning fast website builder is made for sports volunteers. Set up your website in minutes with your logos, colors, and domain name. Create pages, add photos & documents, post your schedules, and more!",
    href: websiteBuilderPath,
  },
  {
    imageSrc: registrationImage,
    tag: "Online Registration",
    title: "Supercharge your registration",
    description:
      "Our built-in registration software helps your organization grow. Create registration forms, collect payments, set up discount codes, and more - all with a few clicks.",
    href: registrationPath,
  },
  {
    imageSrc: communicationImage,
    tag: "Communication",
    title: "Communicate Like a Pro",
    description:
      "Our messaging feature streamlines all communication at your organization. Send text and email messages to teams, divisions, or your entire organization. Messages can be sent from any device so parents and coaches are always in the know.",
    href: communicationPath,
  },
];

export const homepageFeatures2 = [
  {
    imageSrcMobile: schedulingImageMobile,
    imageSrcDesktop: schedulingImageDesktop,
    title: "Schedule Events",
    description:
      "Use our simple scheduling features to post game schedules and events on your website.",
    href: schedulingPath,
  },
  {
    imageSrcMobile: backgroundChecksImageMobile,
    imageSrcDesktop: backgroundChecksImageDesktop,
    title: "Run Background Checks",
    description:
      "Keep players safe with background checks for coaches and volunteers. Send invitations to your volunteers with one click and get results quickly.",
    href: backgroundCheckPath,
  },
  {
    imageSrcMobile: donationsImageMobile,
    imageSrcDesktop: donationsImageDesktop,
    title: "Increase Donations",
    description:
      "Donation opportunities are built into your website and registration. Encourage parents, coaches, and community members to make donations to your cause!",
    href: donationsPath,
  },
];
