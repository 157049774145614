import { css } from "@emotion/react";
import { Body } from "../Typography";

interface HomepageHeroJoinProps {}

const HomepageHeroJoin = ({}: HomepageHeroJoinProps) => {
  return (
    <Body
      css={css`
        font-size: 20px;
        font-weight: 500;
        line-height: 140%;
      `}
    >
      Join{" "}
      <span
        css={css`
          font-weight: 800;
          font-height: 24px;
          position: relative;
        `}
      >
        {process.env.organizationCount}+
        <svg
          width="81"
          height="13"
          viewBox="0 0 81 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          css={css`
            position: absolute;
            bottom: -12px;
            left: -7px;
          `}
        >
          <path
            d="M1.50001 11.5001C18.4999 4.99986 42.9999 -0.000104752 79.7203 1.23262"
            stroke="#59B9FF"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      </span>{" "}
      sports organizations.
    </Body>
  );
};

export default HomepageHeroJoin;
