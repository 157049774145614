import { mq } from "@/constants/breakpoints";
import { css } from "@emotion/react";
import { HTMLAttributes } from "react";

interface PlayButtonProps extends HTMLAttributes<SVGElement> {}

const PlayButton = ({ ...props }: PlayButtonProps) => {
  return (
    <svg
      width="68"
      height="68"
      viewBox="0 0 68 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={css`
        padding: 16px;
        cursor: pointer;
        transition: top 0.2s ease-in-out;
        &:hover {
          top: -5px;
        }
        ${mq["lg"]} {
          width: 106px;
          height: auto;
        }
      `}
      {...props}
    >
      <g filter="url(#filter0_b_653_41340)">
        <circle
          cx="34"
          cy="34"
          r="34"
          fill="url(#paint0_linear_653_41340)"
          fillOpacity="0.1"
        />
        <circle cx="34" cy="34" r="33.5" stroke="#C7C4EF" strokeOpacity="0.1" />
      </g>
      <circle cx="34" cy="34" r="25" fill="#243B4A" />
      <path
        d="M41.0691 33.742C41.1857 33.8121 41.2266 33.9094 41.2266 34C41.2266 34.0906 41.1857 34.1879 41.0691 34.258L30.8017 40.441C30.6716 40.5194 30.5248 40.5167 30.4013 40.4508C30.2795 40.3859 30.2266 40.2882 30.2266 40.183L30.2266 27.817C30.2266 27.7118 30.2795 27.6141 30.4012 27.5492C30.5248 27.4833 30.6716 27.4806 30.8017 27.559L41.0691 33.742Z"
        stroke="white"
        strokeWidth="2"
      />
      <defs>
        <filter
          id="filter0_b_653_41340"
          x="-80"
          y="-80"
          width="228"
          height="228"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="40" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_653_41340"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_653_41340"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_653_41340"
          x1="-2.3521e-06"
          y1="-2.51851"
          x2="80.4259"
          y2="17.408"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="#BEDCFF" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default PlayButton;
