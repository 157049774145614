import {
  mutedGreenColor,
  darkGreenColor,
  darkColor,
  textColor,
} from "@/constants/colors";
import { css } from "@emotion/react";
import Link from "next/link";
import {
  BodySemiBold12,
  Heading,
  H7Css,
  H4Css,
  Body,
  BodyRegular14Css,
} from "../Typography";
import Image from "next/image";
import { mq } from "@/constants/breakpoints";
import LinkWithArrow from "../Buttons/LinkWithArrow";
import {
  backgroundCheckPath,
  communicationPath,
  donationsPath,
  registrationPath,
  schedulingPath,
  websiteBuilderPath,
} from "@/constants/paths";
import { useMemo } from "react";

const TextSection = ({ tag, title, description, href }: any) => {
  const learnMoreLabel = useMemo(() => {
    let label = "Learn more about ";
    switch (href) {
      case websiteBuilderPath:
        label += "the Website Builder";
        break;
      case communicationPath:
        label += "Communication Tools";
        break;
      case registrationPath:
        label += "Online Registration";
        break;
      case schedulingPath:
        label += "Scheduling";
        break;
      case backgroundCheckPath:
        label += "Background Checks";
        break;
      case donationsPath:
        label += "Donations";
        break;
    }
    return label;
  }, [href]);

  return (
    <div>
      {tag && (
        <div
          css={css`
            background-color: ${mutedGreenColor};
            margin-bottom: 14px;
            width: fit-content;
            padding: 4px 13px;
            border-radius: 40px;
            color: ${darkGreenColor};
          `}
        >
          <BodySemiBold12>{tag}</BodySemiBold12>
        </div>
      )}
      <Heading
        level={3}
        css={css`
          color: ${darkColor};
          ${H7Css}
          ${mq["lg"]} {
            ${H4Css}
          }
        `}
      >
        {title}
      </Heading>
      <Body
        css={css`
          ${BodyRegular14Css}
          margin-top: 14px;
          color: ${textColor};
          ${mq["lg"]} {
            font-size: 16px;
          }
        `}
      >
        {description}
      </Body>
      {href !== donationsPath && (
        <div
          css={css`
            margin-top: 24px;
          `}
        >
          <Link href={href}>
            <LinkWithArrow>{learnMoreLabel}</LinkWithArrow>
          </Link>
        </div>
      )}
    </div>
  );
};

interface CardProps {
  title: string;
  description: string;
  imageSrcMobile: any;
  imageSrcDesktop?: any;
  href: string;
  tag?: string;
  index: number;
}

const Card = ({
  index,
  title,
  description,
  imageSrcMobile,
  imageSrcDesktop,
  tag,
  href,
}: CardProps) => {
  const additionalFeatures = Boolean(imageSrcDesktop);

  return (
    <div
      css={css`
        padding-top: 60px;
        display: flex;
        flex-direction: column;
        gap: 30px;
        ${mq["md"]} {
          gap: 44px;
          align-items: center;
          flex-direction: ${index % 2 === 0 ? "row" : "row-reverse"};
        }
        ${mq["lg"]} {
          gap: 52px;
          ${
            additionalFeatures
              ? `
          flex-direction: column;
          `
              : `
          padding-top: 110px;
          `
          }
          ${mq["xl"]} {
            gap: 120px;
          }
      `}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <Image
          src={imageSrcMobile}
          alt={`Jersey Watch ${tag}`}
          css={css`
            display: block;
            max-width: 100%;
            height: auto;
            ${mq["lg"]} {
              display: none;
            }
          `}
        />
        <Image
          src={imageSrcDesktop || imageSrcMobile}
          alt={`Jersey Watch ${tag}`}
          css={css`
            display: none;
            max-width: 100%;
            height: auto;
            ${mq["lg"]} {
              display: block;
            }
          `}
        />
      </div>
      <div
        css={css`
          ${mq["md"]} {
            max-width: 300px;
          }
          ${mq["lg"]} {
            max-width: 420px;
          }
          ${mq["xl"]} {
            max-width: 470px;
          }
        `}
      >
        <TextSection
          tag={tag}
          title={title}
          description={description}
          href={href}
        />
      </div>
    </div>
  );
};

export default Card;
