import { darkColor, mutedBlueColor, textColor } from "@/constants/colors";
import { css } from "@emotion/react";
import {
  Heading,
  Body,
  BodyMedium14Css,
  BodyMedium20Css,
  H6Css,
  BodyMedium16Css,
} from "../Typography";
import Image from "next/image";
import { mq } from "@/constants/breakpoints";
import { contentfulLoader } from "../ContentfulImage/ContentfulImage";

interface TestimonialProps {
  testimonial: any;
}

const Testimonial = ({ testimonial }: TestimonialProps) => {
  return (
    <div
      css={css`
        padding: 22px 24px;
        background-color: ${mutedBlueColor};
        border-radius: 12px;
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        ${mq["md"]} {
          align-items: center;
          padding: 40px 24px;
          flex-direction: row;
        }
      `}
    >
      <Body
        css={css`
          color: ${darkColor};
          ${BodyMedium14Css}
          ${mq["lg"]} {
            ${BodyMedium20Css}
          }
        `}
      >
        “{testimonial.quote}”
      </Body>
      <div
        css={css`
          display: flex;
          gap: 16px;
          min-width: 200px;
          ${mq["lg"]} {
            min-width: 260px;
          }
        `}
      >
        <Image
          width="71"
          height="71"
          loader={contentfulLoader}
          src={
            testimonial.organizationLogo.url ||
            testimonial.organizationLogo.fields?.file?.url
          }
          alt={testimonial.organizationName}
        />
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: center;
          `}
        >
          <Heading
            level={4}
            css={css`
              color: ${darkColor};
              font-size: 16px;
              font-weight: 400;
              line-height: 130%;
              ${mq["lg"]} {
                ${H6Css}
              }
            `}
          >
            {testimonial.quoteAuthor}
          </Heading>
          <Body
            css={css`
              color: ${textColor};
              margin-top: 2px;
              font-size: 12px;
              font-weight: 500;
              line-height: 136%;
              ${mq["lg"]} {
                ${BodyMedium16Css}
              }
            `}
          >
            {testimonial.organizationName}
          </Body>
        </div>
      </div>
    </div>
  );
};

export default Testimonial;
