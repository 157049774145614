import Image, { ImageProps } from "next/image";

export const contentfulLoader: any = ({
  src,
  width,
  quality,
}: {
  src: string;
  width: number;
  quality: number;
}) => {
  return `${src}?w=${width}&q=${quality || 75}&fm=webp`;
};

interface ContentfulImageProps extends ImageProps {
  alt: string;
}

const ContentfulImage = ({ alt, ...props }: ContentfulImageProps) => {
  return <Image loader={contentfulLoader} alt={alt} {...props} />;
};

export default ContentfulImage;
