import { blueColor, lightBlueColor } from "@/constants/colors";
import ArrowLeftIcon from "@/svg/ArrowLeftIcon";
import { css } from "@emotion/react";
import { HTMLAttributes } from "react";

interface ArrowButtonProps extends HTMLAttributes<HTMLButtonElement> {
  direction: "left" | "right";
}

const ArrowButton = ({ direction, ...props }: ArrowButtonProps) => {
  return (
    <button
      css={css`
        display: flex;
        background: transparent;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        width: 50px;
        height: 50px;
        cursor: pointer;
        border: 2px solid ${blueColor};
        transition: all 0.2s ease-in-out;
        &:hover {
          border-color: #c0e7fd;
        }
        &:active {
          background-color: ${blueColor};
          border-color: ${blueColor};
        }
      `}
      {...props}
    >
      <ArrowLeftIcon
        css={css`
          color: ${lightBlueColor};
          transform: ${direction === "right" ? "rotate(180deg)" : "unset"};
        `}
      />
    </button>
  );
};

export default ArrowButton;
