import { mq } from "@/constants/breakpoints";
import { lightBlueColor } from "@/constants/colors";
import ArrowRightIcon from "@/svg/ArrowRightIcon";
import { css } from "@emotion/react";
import Link from "next/link";
import { Heading } from "../Typography";

interface SportProps {
  title: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  href: string;
}

const Sport = ({ title, Icon, href }: SportProps) => {
  return (
    <Link href={href}>
      <div
        css={css`
          padding: 14px 16px;
          background: linear-gradient(
            103.92deg,
            rgba(255, 255, 255, 0.1) -0.74%,
            rgba(190, 220, 255, 0.1) 99.87%
          );
          border-radius: 8px;
          border: 1px solid rgba(255, 255, 255, 0.1);
          backdrop-filter: blur(40px);
          transition: all 0.2s ease-in-out;
          &:hover {
            outline: 12px solid ${lightBlueColor};
            background: #fff;
            * {
              color: ${lightBlueColor};
            }
          }
          ${mq["lg"]} {
            padding: 27px 30px;
          }
        `}
      >
        <div
          css={css`
            display: flex;
            justify-content: space-between;
          `}
        >
          <Heading
            level={3}
            css={css`
              color: #fff;
              font-size: 14px;
              font-weight: 400;
              ${mq["lg"]} {
                font-size: 20px;
              }
            `}
          >
            {title}
          </Heading>
          <ArrowRightIcon
            css={css`
              color: transparent;
            `}
          />
        </div>
        <Icon
          css={css`
            margin-top: 14px;
            color: #fff;
            ${mq["lg"]} {
              margin-top: 30px;
              width: 50px;
              height: auto;
            }
          `}
        />
      </div>
    </Link>
  );
};

export default Sport;
